<template>
  <div class="page-wrap px-2.5 py-3.5">
    <!-- 筛选栏 -->
    <div class="nav flex items-center mt-0.5 px-2.5 py-2 bg-white" style="padding-right:0">
      <div class="screen-wrap" @click="screen_show=true">
        <div class="flex-1 van-ellipsis">{{ screen_region.areaName }}</div>
        <van-icon name="arrow-down" color="#666" size="12" />
      </div>
    </div>
    <div class="list">
      <div class="flex items-center mb-2 px-3 py-4 rounded bg-white" v-for="(item, index) in list" :key="index">
        <div class="flex-1 text-sm font-medium ">{{item.cityName}}</div>
        <div class="btn text-sm px-2 rounded border border-blue-400 text-blue-400 " @click.stop="editDistrict(item)">编辑</div>
        <div class="ml-5 text-sm underline text-gray-400" v-show="authority=='true'" @click="delClick(item.id,index)">删除</div>
      </div>
    </div>
    <div class="kong"></div>
    <div class="btn-box">
      <div class="btn" @click="$router.push({ path:'/regulate/city/detail' })">新增</div>
    </div>
    <!-- 筛选弹窗 -->
    <van-popup v-model="screen_show" position="bottom">
      <van-picker
        title="选择区域"
        show-toolbar
        :columns="region_list"
        value-key="areaName"
        @confirm="screenConfirm"
        @cancel="screen_show=false"
      />
    </van-popup>
  </div>
</template>
<script>
import { areaList, delCity, cityList } from '../../../api'
import { Picker, Popup, List, Icon } from "vant";
export default {
  name:'city',
  components: {
    [Icon.name]: Icon,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [List.name]: List,
  },
  data () {
    return {
      region_list: [{ id: '', areaName: "区域" }],
      // 当前选择的区域
      screen_region: { id: '', areaName: "区域" },
      // 打开筛选弹窗
      screen_show: false,
      list: [],
      authority: 'false'
    }
  },
  methods:{
    screenConfirm(val) {
      this.screen_region = val
      this.cityList()
      this.screen_show = false
    },
    editDistrict(item) {
      this.$router.push({ path:'/regulate/city/detail',query:item })
    },
    //区域列表
    areaList() {
      this.$toast.loading({
        forbidClick: true,
        duration: 0
      })
      areaList({}).then(res=>{
        var data = res.data.data
        // if (data.length>0) {
        //   this.screen_region = data[0]
          
        // }
        this.region_list = this.region_list.concat(data)
      })
    },
    //城市列表
    cityList() {
      this.$toast.loading({
        forbidClick: true,
        duration: 0
      })
      cityList({
        areaId: this.screen_region.id
      }).then(res=>{
        this.list = res.data.data
      }).finally(res=>{
        this.$toast.clear()
      })
    },
    delClick(id,index){
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除该城市',
      })
        .then(() => {
          // on confirm
          this.$toast.loading({
              message: '',
              forbidClick: true,
              duration: 0
          });
          delCity(id).then((res)=>{
            this.$toast.success('删除成功');
            this.list.splice(index, 1)
          }).catch(()=>{
            this.$toast.clear()
          })
        })
    }
  },
  created() {
    this.authority = localStorage.getItem('authority')
    this.areaList()
    this.cityList()
  }
}
</script>
<style scoped>
  .page-wrap{
    position: relative;
    background-color: #f6f6f6;
    min-height:100vh;
  }
  .btn-box{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #fff;
  }
  .btn-box .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 339px;
    height: 42px;
    background: linear-gradient(-42deg, #7FA9FF, #6497FF);
    border: 1px solid #6A99F9;
    border-radius: 5px;
    box-shadow: 0.125rem 0.1875rem 0.6875rem 0 rgb(255 255 255 / 26%) inset, -0.0625rem -0.0625rem 0.5625rem 0 rgb(90 140 243 / 67%) inset;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
  }
  .kong{
    width: 100%;
    height: 55px;
  }
  .nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    margin-top: 0;
  }
.list{
  margin-top: 40px;
}
</style>
